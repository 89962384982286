<script setup>
import StarlitEdgeLogo from "@/assets/starlit-edge-logo.png";
</script>

<template>
  <div class="hello">
    <img alt="Starlit Edge Logo" :src="StarlitEdgeLogo" width="200" height="200" />
    <p>
      <br />
      Next generation of the internet. <br />
      <span style="color: #78d6ff;">{{ display }}</span> <br />
      <br />
      Coming soon... <br />
      <br />
      All rights reserved.<br />
      Powered by <b>Starlit Edge Network</b> &copy; 2024.
    </p>
  </div>
</template>

<script>
export default {
  name: 'StarlitEdge',
  metaInfo: {
    title: 'Starlit Edge Network',
    meta: [
      {
        name: 'description',
        content: 'Next generation of the internet.'
      }
    ]
  },
  data() {
    return {
      display: '',
      messages: ['Privacy and Sovereignty', 'Sustainablity', 'Proliferation', 'High Bandwidth', 'High Availability', 'Low Latency'],
      currentMessageIndex: 0,
      isDeleting: false,
      currentIndex: 0,
      StarlitEdgeLogo: StarlitEdgeLogo,
    };
  },
  mounted() {
    this.typeEffect();
  },
  methods: {
    typeEffect() {
      const speed = 50; // Speed of typing in milliseconds
      setInterval(() => {
        const currentMessage = this.messages[this.currentMessageIndex];
        if (!this.isDeleting) {
          // Typing forward
          this.display = currentMessage.substring(0, this.currentIndex + 1);
          this.currentIndex++;
          if (this.currentIndex === currentMessage.length) {
            // Start deleting after a pause
            setTimeout(() => {
              this.isDeleting = true;
            }, 200); // Pause before deleting
          }
        } else {
          // Deleting
          this.display = currentMessage.substring(0, this.currentIndex - 1);
          this.currentIndex--;
          if (this.currentIndex === 0) {
            this.isDeleting = false;
            this.currentMessageIndex = (this.currentMessageIndex + 1) % this.messages.length; // Move to the next message or loop back to the first
          }
        }
      }, speed);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #78d6ff;
}
</style>
