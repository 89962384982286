<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      This is a private system. All rights reserved. Powered by <a href="https://mc256.dev">mc256.dev</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivateSystem',
  metaInfo: {
    title: 'yuri.moe - Private System',
    meta: [
      {
        name: 'description',
        content: 'This is a private system.'
      }
    ]
  },
  props: {
    msg: String
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #78d6ff;
}
</style>
