<template>
  <div class="home">
    <PrivateSystem msg="Private System" v-if="yuri" />
    <StarlitEdge v-if="starlitedge" />
  </div>
</template>


<style>
.home {
  padding-top: 50px;
}
</style>
<script>
// @ is an alias to /src
import PrivateSystem from '@/components/PrivateSystem.vue'
import StarlitEdge from '@/components/StarlitEdge.vue'

export default {
  name: 'Home',
  components: {
    PrivateSystem,
    StarlitEdge
  },
  data() {
    console.log(process.env)
    return {
      yuri: process.env.VUE_APP_DISPLAY_TYPE == 'yuri',
      starlitedge: process.env.VUE_APP_DISPLAY_TYPE == 'starlitedge'
    }
  }
}
</script>
